import { Box, Heading, Text } from '@chakra-ui/react';

interface Props {
  heading: string;
  body?: string;
}

export const TextIntro: React.FC<Props> = ({ heading, body }) => {
  return (
    <Box
      maxW={'50rem'}
      m={'0 auto'}
      px={'4'}
      textAlign={'center'}
      color={'white'}
    >
      <Heading as={'h1'} fontSize={'1.75rem'} fontWeight={'bold'}>
        {heading}
      </Heading>
      {body && <Text pt={8}>{body}</Text>}
    </Box>
  );
};
