import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useState } from 'react';

interface Props {
  title: string;
  content: string;
  url: string;
}

export const Subject: React.FC<Props> = ({ title, content, url }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
    setIsClicked(false);
    setIsPreview(false);
  };

  const readMoreHandler = () => {
    setIsClicked(true);
  };

  const previewHandler = () => {
    if (isClicked) setIsPreview(true);
  };

  return (
    <Box
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      maxW={['400px', null, '620px']}
      maxH="170px"
      borderRadius={'20px'}
      bgImg={`url(${url})`}
      bgPos={'60% 70%'}
      backgroundSize="cover"
      transition="0.3s linear"
    >
      <Box
        h={'170px'}
        position="relative"
        transition="0.3s linear"
        p={isClicked ? '0.8rem 1.5rem' : '0.8rem 1.5rem'}
        bgColor="white"
        width={[isHovered ? '65%' : '60%', null, isHovered ? '55%' : '50%']}
        minW={[isClicked ? '100%' : '60%', null, isClicked ? '100%' : '50%']}
        borderTopLeftRadius="20px"
        borderBottomLeftRadius="20px"
        borderTopRightRadius={isClicked && '20px'}
        borderBottomRightRadius={isClicked && '20px'}
      >
        <Heading
          as="h2"
          position={'absolute'}
          fontSize={[
            isHovered ? '0.9rem' : '0.7rem',
            null,
            isHovered ? '1.2rem' : '1rem',
          ]}
          transition="0.3s linear"
          fontWeight={700}
          letterSpacing={'.75px'}
          color="black"
        >
          {title}
        </Heading>
        <Text
          color="grey"
          fontSize={'0.7rem'}
          mt={['1.5rem', null, '1.7rem']}
          p={isClicked && '0rem 0.5rem 0.5rem 0'}
          fontWeight={isClicked ? 700 : null}
          noOfLines={isClicked ? null : 4}
          overflowY={isClicked ? 'scroll' : 'hidden'}
          height={isClicked && '120px'}
        >
          {content}
        </Text>

        <Box
          position={isClicked ? 'absolute' : 'relative'}
          bottom={isClicked ? '-6' : '0'}
          transition="0.3s ease"
        >
          <Button
            size={'xs'}
            fontSize={['0.5rem', '0.6rem', '0.7rem']}
            target="_blank"
            href={isPreview ? 'https://app.valearnis.com/preview' : null}
            as="a"
            px={isHovered ? '1.5rem ' : '1rem'}
            bgColor="#ffbd00"
            color="black"
            borderRadius="40px"
            onMouseEnter={previewHandler}
            onClick={readMoreHandler}
            transition="0.3s ease"
          >
            {isClicked ? 'Preview' : 'Read More'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
