import { useEffect, useState } from 'react';
import { Img, Box, Fade } from '@chakra-ui/react';

const images = [
  {
    src: 'sneak-peak-curriculum',
    alt: 'Curriculum screen preview',
  },
  {
    src: 'sneak-peak-lesson-theory',
    alt: 'Lesson theory screen preview',
  },
  {
    src: 'sneak-peak-profile',
    alt: 'Profile screen preview',
  },
  {
    src: 'sneak-peak-reports',
    alt: 'Reports screen preview',
  },
];

export const SneakPeak: React.FC = () => {
  const [currentPos, setCurrentPos] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentPos >= images.length - 1) {
        setCurrentPos(0);
      } else {
        setCurrentPos(currentPos + 1);
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentPos]);

  return (
    <Box
      pos={'relative'}
      pb={[8, 12, 16]}
      _after={{
        content: `""`,
        display: 'block',
        pb: ['66.67%', null, null, null, '80vh'],
      }}
    >
      {images &&
        images.map((image, i) => (
          <Fade
            key={image.src}
            in={i === currentPos}
            transition={{ enter: { duration: 1.25 }, exit: { duration: 1 } }}
          >
            <Box position={'absolute'} width={'100%'} margin={'0 auto'}>
              <Img
                src={`https://cdn.valearnis.com/vw/img/${image.src}.webp`}
                alt={image.alt}
                maxH={'80vh'}
                width={'auto'}
                margin={'0 auto'}
                htmlWidth={'1920px'}
                htmlHeight={'1280px'}
              />
            </Box>
          </Fade>
        ))}
    </Box>
  );
};

export default SneakPeak;
