import {
  Center,
  Heading,
  Text,
  Flex,
  Img,
  Box,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

import data from '@public/meta.json';
import fonts from '@definitions/chakra/theme/foundations/fonts';

export const PlatformUses: React.FC = () => {
  if (data?.platformUses) {
    return (
      <Center
        flexFlow={'column'}
        mb={12}
        py={[12, 16, 20, 24, 28]}
        textAlign={'center'}
      >
        <Heading as={'h2'} textTransform={'uppercase'}>
          Multipurpose Platform
        </Heading>
        <Text
          maxW={['27.5rem', null, '38.75rem']}
          mt={10}
          px={6}
          fontSize={['1rem', '1.125rem', '1.25rem', null, '1.5rem', '1.625rem']}
          letterSpacing={'.5px'}
        >
          Valearnis is an educational platform suited for various learning
          activities.
        </Text>
        <Flex
          flexFlow={['column', null, null, 'row']}
          alignContent={'center'}
          alignItems={'center'}
          maxW={'75rem'}
          width={'100%'}
        >
          <Img
            src={
              'https://cdn.valearnis.com/vw/img/multipurpose-platform-people-icon.svg'
            }
            maxW={'26.25rem'}
            order={[null, null, null, 1]}
          />
          <Flex
            flexFlow={['column', null, 'row']}
            justifyContent={'space-evenly'}
            alignItems={['center', null, 'flex-start']}
            width={'100%'}
            textAlign={'left'}
          >
            {data.platformUses.map((list) => {
              if (list.heading && list.items) {
                return (
                  <Box key={list.heading}>
                    <Heading
                      as={'h3'}
                      mt={12}
                      fontFamily={fonts.body}
                      fontSize={'1.125rem'}
                      fontWeight={600}
                      letterSpacing={'0.5px'}
                    >
                      {list.heading}
                    </Heading>
                    <UnorderedList
                      listStyleType={'none'}
                      m={0}
                      mt={5}
                      textAlign={'left'}
                    >
                      {list.items.map((item) => (
                        <ListItem key={item} display={'flex'} mt={6}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 110 110"
                            width={'24px'}
                            height={'24px'}
                          >
                            <g>
                              <circle
                                cx="54.83"
                                cy="55"
                                r="54"
                                fill="#2871d1"
                              />
                              <path
                                d="M31.32 54.7L44.8 71.77l33.55-33.54"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeWidth="5"
                              />
                            </g>
                          </svg>
                          <Text my={0} ml={7}>
                            {item}
                          </Text>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
                );
              }
            })}
          </Flex>
        </Flex>
      </Center>
    );
  } else {
    return <></>;
  }
};

export default PlatformUses;
