import { Img } from '@chakra-ui/react';

interface Props {
  width?: string;
  height?: string;
}

export const ValearnisLogoWhite: React.FC<Props> = ({
  width = '140px',
  height = '36px',
}) => {
  return (
    <Img
      data-test={'icon'}
      src={'/icons/valearnis-logo-white.svg'}
      alt={'Valearnis Logo'}
      width={width}
      height={height}
      htmlWidth={width}
      htmlHeight={height}
      transition={'.3s all ease'}
    />
  );
};
