import { useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VisuallyHidden,
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

interface FormValues {
  name: string;
  email: string;
}

const SubscribeSchema = Yup.object().shape({
  name: Yup.string().max(100, 'Max of 100 characters').required('Required'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .min(6, 'Please enter at least 6 characters')
    .max(100, 'Max of 100 characters')
    .required('Required'),
});

export const SubscribeForm: React.FC = () => {
  const [formError, setFormError] = useState('');
  const [formSent, setFormSent] = useState(false);
  const CForm = chakra(Form);

  const initialValues: FormValues = {
    name: '',
    email: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SubscribeSchema}
      onSubmit={async (
        values: FormValues,
        actions: FormikHelpers<FormValues>,
      ) => {
        setFormSent(false);

        try {
          const res = await fetch('/.netlify/functions/subscribe', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(values),
          });

          if (res.ok) {
            actions.resetForm();
            setFormError('');
            setFormSent(true);
          } else {
            setFormError(
              'Sorry, something went wrong (you might already be subscribed).',
            );
          }
        } catch (err) {
          setFormError(err);
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {(props) => (
        <CForm
          d={['block', 'flex']}
          flexFlow={[null, 'row wrap']}
          justifyContent={'space-between'}
          w={'100%'}
          maxW={'50rem'}
          px={8}
          textAlign={'center'}
        >
          {(formSent || formError) && (
            <Box my={8} flex={'1 0 100%'}>
              {formSent && (
                <Alert status="success" color={'#2D3748'} borderRadius={'10px'}>
                  <AlertIcon />
                  Thanks for joining us!
                </Alert>
              )}
              {formError && (
                <Alert status="error" color={'#2D3748'} borderRadius={'10px'}>
                  <AlertIcon />
                  {formError}
                </Alert>
              )}
            </Box>
          )}
          <Field name="name">
            {({ field, form }) => (
              <FormControl
                id="name"
                w={[null, '48%', '38%']}
                mb={[6, 2, 0]}
                mt={6}
                isInvalid={form.errors.name && form.touched.name}
                isRequired
              >
                <VisuallyHidden>
                  <FormLabel>Name</FormLabel>
                </VisuallyHidden>
                <Input type="name" placeholder={'Name'} {...field} />
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="email">
            {({ field, form }) => (
              <FormControl
                id="email"
                w={[null, '48%', '38%']}
                mb={[6, 2, 0]}
                mt={6}
                isInvalid={form.errors.email && form.touched.email}
                isRequired
              >
                <VisuallyHidden>
                  <FormLabel>Email</FormLabel>
                </VisuallyHidden>
                <Input type="email" placeholder={'Email'} {...field} />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Button
            type="submit"
            h={[null, null, 10]}
            mt={[2, null, 6]}
            // mb={[null, null, 6]}
            ml={['auto', null, 0]}
            mr={['auto', 0]}
            variant={'transparent'}
            size={'md'}
            isLoading={props.isSubmitting}
            loadingText={'Sending...'}
            spinnerPlacement="end"
          >
            Subscribe
          </Button>
        </CForm>
      )}
    </Formik>
  );
};
