import NextLink from 'next/link';
import {
  Collapse,
  Flex,
  Icon,
  Link,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';

import { NavItem } from '../..';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface MobileNavProps {
  navItems: NavItem[];
  onMenuClose: () => void;
}

export const MobileNav: React.FC<MobileNavProps> = ({
  navItems,
  onMenuClose,
}) => {
  return (
    <Stack
      as="nav"
      w={'100%'}
      bg={'transparent'}
      display={{ md: 'none' }}
      p={4}
      textAlign={'center'}
    >
      {navItems.map((navItem) => (
        <MobileNavItem
          key={navItem.label}
          onMenuClose={onMenuClose}
          navItem={navItem}
        />
      ))}
    </Stack>
  );
};

interface MobileNavItemProps {
  onMenuClose: () => void;
  navItem: NavItem;
}

const MobileNavItem: React.FC<MobileNavItemProps> = ({
  onMenuClose,
  navItem,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack
      spacing={4}
      onClick={() => {
        // TODO: handle opening subnav correctly when
        navItem.children
          ? onToggle()
          : navItem.href.includes('#') && onMenuClose();
      }}
    >
      <NextLink href={navItem.href ?? '#'} passHref>
        <Flex
          as={Link}
          href={navItem.href ?? '#'}
          d={'block'}
          w={'100%'}
          justify={'space-between'}
          align={'center'}
          m={'0 auto'}
          py={4}
          fontSize={['1.5rem', '1.75rem']}
          letterSpacing={'0.75px'}
          fontWeight={600}
          color={'#fff'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          {navItem.label}
          {navItem.children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
      </NextLink>
      {navItem.children && (
        <Collapse
          in={isOpen}
          animateOpacity
          style={{ marginTop: '0!important' }}
        >
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={'gray.200'}
            align={'start'}
          >
            {navItem.children.map((child) => (
              <NextLink href={child.href} passHref>
                <Link key={child.label} py={2}>
                  {child.label}
                </Link>
              </NextLink>
            ))}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
};

export default MobileNav;
