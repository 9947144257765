import { Box, Flex, FlexProps } from '@chakra-ui/react';

import { Header, Footer } from '@components';

export const PageWrapper: React.FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <>
      <Header />
      <Box
        minH={'100vh'}
        px="35px"
        color="#fff"
        bg="linear-gradient(#1B4DB2, #44229C)"
      >
        <Flex
          as={'main'}
          direction={'column'}
          maxW={'80rem'}
          m={'0 auto'}
          pt={'32'}
          overflow={'hidden'}
          {...rest}
        >
          {children}
        </Flex>
        <Footer />
      </Box>
    </>
  );
};
