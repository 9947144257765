import {
  Box,
  Heading,
  Text,
  Image,
  useMediaQuery,
  Container,
} from '@chakra-ui/react';
import { ArrowRightIcon, ArrowLeftIcon } from '@chakra-ui/icons';
import { useState, useEffect } from 'react';

export const Testimonials: React.FC = () => {
  // Array of testimonials
  const testimonialText = [
    {
      text: '"Excellent as an after-school aid for my son!"',
      image: '/testimonial/A.jpg',
      name: 'Roopal',
      subtitle: 'VIC',
      position: { x: 20, y: 30 },
    },
    {
      text: '"Valearnis is really useful over the school holidays, allowing my students to stay up to date on their learning."',
      image: '/testimonial/B.png',
      name: 'Liam',
      subtitle: 'WA',
      position: { x: -220, y: 250 },
    },
    {
      text: '"A great homeschooling resource, really keeps them engaged."',
      image: '/testimonial/C.jpg',
      name: 'Jason',
      subtitle: 'TAS',
      position: { x: -450, y: -55 },
    },
    {
      text: '"On-demand reporting makes so much sense, way better than waiting until the end of term for your results."',
      image: '/testimonial/D.png',
      name: 'Callum',
      subtitle: 'WA',
      position: { x: -800, y: 140 },
    },
    {
      text: '"As an ex-teacher, I can confirm that this engaging and professional platform is invaluable; particularly for students who require quality content when learning remote or substitute lessons"',
      image: '/testimonial/E.jpg',
      name: 'Phil',
      subtitle: 'WA',
      position: { x: -950, y: -73 },
    },
    {
      text: '"Using Valearnis allows me to create excellent lessons for my students, and I encourage them to use it as a homework tool as well"',
      image: '/testimonial/F.jpg',
      name: 'Sharan',
      subtitle: 'WA',
      position: { x: -1350, y: 280 },
    },
    {
      text: '"Really useful as a substitute for tutoring, I was initially spending $300 per month on private tutoring before using Valearnis"',
      image: '/testimonial/G.jpg',
      name: 'Serena',
      subtitle: 'NSW',
      position: { x: -1450, y: 0 },
    },
  ];

  // Button logic
  const [cardPosition, setCardPosition] = useState(0);

  function moveLeft() {
    setCardPosition((prevPosition) => prevPosition - 12);
  }
  function moveRight() {
    setCardPosition((prevPosition) => prevPosition + 12);
  }

  // Auto move for when DOM loads, cards dont move into position otherwise.
  function autoMove() {
    setCardPosition(0.1);
  }
  useEffect(() => {
    autoMove();
  }, []);

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return (
    <>
      <Box
        h={'110vh'}
        minH={'600px'}
        w={'100vw'}
        bgImage={"url('BG A.jpg')"}
        bgPosition={'bottom'}
        position={'relative'}
        left={'-35px'}
        bgSize={'cover'}
        mb={'13rem'}
      >
        <Box
          display={'flex'}
          flexDir={'column'}
          alignItems="center"
          justifyContent={'center'}
          padding={'2%'}
          mb="3rem"
        >
          <Heading size={'2xl'} mb="1rem">
            Testimonials
          </Heading>
          <Text fontSize={'2xl'} textAlign={'center'}>
            Hear from our valued Customers, Partners and Supporters
          </Text>
          <Box display={'flex'} gap="2rem">
            {/* Arrow buttons */}
            <ArrowLeftIcon
              _hover={{ cursor: 'pointer' }}
              boxSize={'9'}
              onClick={moveRight}
              onLoad={moveRight}
            />
            <ArrowRightIcon
              _hover={{ cursor: 'pointer' }}
              boxSize={'9'}
              onClick={moveLeft}
            />
          </Box>
        </Box>

        <Box
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="3rem"
        >
          {/* Create a card for each item in testimonials array */}
          {testimonialText.map((testimonial, index) => (
            <Container
              key={index}
              w={isLargerThan768 ? '30rem' : '70vw'}
              borderRadius={'20px'}
              border={'3px solid white'}
              p={'0.4rem'}
              bgColor={'#313DAB'}
              color={'#e6e6e6'}
              display={'flex'}
              ml={'35px'}
              left={
                isLargerThan768
                  ? `${testimonial.position.x + cardPosition * 20}px`
                  : cardPosition * 20
              }
              right={
                isLargerThan768
                  ? `${testimonial.position.x + cardPosition * 20}px`
                  : cardPosition * 20
              }
              top={isLargerThan768 ? testimonial.position.y : '0'}
              _hover={{ boxShadow: '2px 2px 50px rgba(255, 255, 255, 0.7)' }}
              transition={'all 0.5s ease'}
              position={'relative'}
            >
              <Container>
                <Text fontSize={'sm'}>{testimonial.text}</Text>
                <hr style={{ paddingBottom: '1rem' }} />
                <Image
                  src={testimonial.image}
                  boxSize={'4rem'}
                  borderRadius={'full'}
                  objectFit={'cover'}
                  float={'left'}
                  mr={'1rem'}
                />
                <Text fontSize={'lg'} fontWeight={'extrabold'}>
                  {testimonial.name}
                  <Text fontSize={'sm'} fontWeight={'normal'}>
                    {testimonial.subtitle}
                  </Text>
                </Text>
              </Container>
            </Container>
          ))}
        </Box>
      </Box>
    </>
  );
};
