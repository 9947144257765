import NextLink from 'next/link';
import {
  Flex,
  Center,
  Img,
  Text,
  Link,
  Stack,
  Box,
  Heading,
  VisuallyHidden,
} from '@chakra-ui/react';
import { css } from '@emotion/react';

import { SubscribeForm } from '@components/forms/subscribe';

import data from '@public/meta.json';

export const Footer: React.FC = () => {
  const iconStyle = css({
    fontSize: 22,
    marginRight: '.5rem',
    marginLeft: '.5rem',
    '@media(min-width: 23.75em)': {
      marginRight: '1rem',
      marginLeft: '1rem',
    },
    '@media(min-width: 62em)': {
      '&:first-of-type': {
        marginLeft: '0',
      },
    },
  });

  return (
    <Center
      as={'footer'}
      flexFlow={['column', null, null, 'row wrap']}
      justifyContent={'space-evenly'}
      mt={20}
      pt={[20, null, null, 28]}
      pb={[10, null, null, 20]}
      px={[null, null, null, 8]}
      letterSpacing={'.5px'}
      borderTop={'2px solid #fff'}
    >
      <Flex
        pos={'relative'}
        flexFlow={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        w={'100%'}
        pb={[16, 20, 24, 32]}
      >
        <VisuallyHidden id={'subscribe'} top={-16} />
        <Heading
          as={'h2'}
          mb={[4]}
          textAlign={'center'}
          fontSize={['1rem', '1.25rem', '1.5rem']}
        >
          Join our mailing list
        </Heading>
        <SubscribeForm />
      </Flex>
      <Flex
        flexDirection={'column'}
        alignItems={['center', null, null, 'flex-start']}
      >
        <NextLink href={'/'} passHref>
          <Link data-testid={'valearnis-logo'}>
            <Img
              data-test={'icon'}
              src={'/icons/valearnis-logo-stacked.svg'}
              alt={'valearnis logo'}
              width={['140px', null, null, '180px']}
              height={['82px', null, null, '105px']}
              htmlWidth={'100px'}
              htmlHeight={'105px'}
            />
          </Link>
        </NextLink>
        <Flex
          mt={[12, null, null, 14]}
          justifyContent={'center'}
          data-testid={'icons-container'}
        >
          {(data?.socialIcons ?? []).map((icon) => (
            <Link key={icon.src} href={icon.href} isExternal css={iconStyle}>
              <Img
                data-test={'icon'}
                src={icon.src}
                alt={icon.alt}
                width={icon.width}
                height={icon.height}
                htmlWidth={icon.width}
                htmlHeight={icon.height}
              />
            </Link>
          ))}
        </Flex>
      </Flex>
      <Stack
        // flex={[null, null, null, 1]}
        w={'100%'}
        maxW={[null, null, null, '50%']}
        mt={[16, null, 20, 0]}
        textAlign={['center', null, null, 'left']}
        flexFlow={['column', null, 'row wrap']}
        justifyContent={['space-evenly']}
        alignItems={['center', null, null, 'baseline']}
      >
        <Flex flexFlow={'column'} justifyContent={'space-evenly'}>
          <NextLink href={'/contact-us'} passHref>
            <Link mb={8}>
              <Heading as={'h3'} fontSize={'1.125rem'} fontWeight={400}>
                CONTACT US
              </Heading>
            </Link>
          </NextLink>
          <Link href={'mailto:hello@valearnis.com'} mb={4}>
            hello@valearnis.com
          </Link>
          <Link
            href={
              'https://www.google.com/maps/dir//Perth+WA/@-32.0397559,115.6813548,10z/data=!4m8!4m7!1m0!1m5!1m1!1s0x2a32966cdb47733d:0x304f0b535df55d0!2m2!1d115.861309!2d-31.9523123'
            }
            isExternal
          >
            Perth, WA, Australia
          </Link>
        </Flex>
        <Flex
          flexFlow={'column'}
          justifyContent={'space-evenly'}
          mt={[null, null, null, 0]}
          pt={[16, null, 0]}
        >
          <Heading as={'h3'} mb={8} fontSize={'1.125rem'} fontWeight={400}>
            LEGAL
          </Heading>
          <NextLink
            href={'https://cdn.valearnis.com/vw/docs/privacypolicy.pdf'}
            passHref
          >
            <Link isExternal>Privacy policy</Link>
          </NextLink>
        </Flex>
      </Stack>
      <Box
        w={[null, null, null, '100%']}
        mt={[16, null, 20, 28]}
        textAlign={'center'}
      >
        <Text my={2}>Created by ManezCo Pty. Ltd.</Text>
        <Text my={2}>ACN: 613640565</Text>
        <Text my={2}>
          <NextLink href={'/'} passHref>
            <Link>&#169; Valearnis 2021</Link>
          </NextLink>
        </Text>
      </Box>
    </Center>
  );
};
