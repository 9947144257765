import NextLink from 'next/link';
import { Box, Button, Flex, Heading, Link } from '@chakra-ui/react';

interface Props {
  heading: string | React.ReactElement;
  children: React.ReactElement;
  button?: {
    label: string;
    link: string;
    disabled?: boolean;
  };
}

export const PricingBlock: React.FC<Props> = ({
  heading,
  children,
  button,
}) => {
  return (
    <Box
      w={'100%'}
      maxW={'32rem'}
      my={['2.5rem', '3rem', '3.5rem']}
      textAlign={'center'}
    >
      <Flex
        flex={'1 0 100%'}
        justifyContent={'center'}
        p={6}
        borderRadius={'10px 10px 0 0'}
        bg={'brand.600'}
      >
        <Heading
          as="h2"
          fontSize={['1.5rem', null, '1.75rem']}
          fontWeight={600}
          letterSpacing={'.75px'}
        >
          {heading}
        </Heading>
      </Flex>
      <Flex
        pos={'relative'}
        flexFlow={'column'}
        flex={'1 0 auto'}
        alignItems={'center'}
        pt={[8, null, 14]}
        pb={[12, null, 16]}
        px={[8, 10, 20]}
        borderRadius={'0 0 10px 10px'}
        bg={'#207EAE'}
      >
        <Box fontWeight={600}>{children}</Box>
        {button && (
          <NextLink href={button.link} passHref>
            <Button
              as={Link}
              pos={'absolute'}
              bottom={'-20px'}
              m={0}
              disabled={button.disabled}
            >
              {button.label}
            </Button>
          </NextLink>
        )}
      </Flex>
    </Box>
  );
};
