import { SimpleGrid, Box, Heading, Center, Img, Text } from '@chakra-ui/react';

import data from '@public/meta.json';

export const Features: React.FC = () => {
  return (
    <Center flexDir={'column'} py={[12, 16, 20, 24, 28]}>
      <Heading as={'h2'} textTransform={'uppercase'}>
        Features
      </Heading>
      <SimpleGrid
        columns={[1, null, 3]}
        spacing={[10, 12, 16, 24]}
        px={10}
        py={14}
        alignItems={'baseline'}
      >
        {(data?.features ?? []).map((feature) => (
          <Box
            key={feature.name}
            data-testid={'container'}
            display={'flex'}
            flexFlow={'column'}
            alignItems={'center'}
            maxW={{ base: '16.25rem', lg: '17.5rem' }}
            mb={2}
            textAlign={'center'}
          >
            <Img
              data-test={'icon'}
              src={`/icons/${feature.icon}`}
              alt={feature.name}
              width={'auto'}
              height={'auto'}
              htmlWidth={'130px'}
              htmlHeight={'120px'}
              maxW={'130px'}
              maxH={'120px'}
            />
            <Text
              py={5}
              fontSize={'1.3125rem'}
              fontWeight={'500'}
              letterSpacing={'.6px'}
            >
              {feature.name}
            </Text>
            {/* <Text textAlign={'center'} fontSize={14}>
              {feature.description}
            </Text> */}
          </Box>
        ))}
      </SimpleGrid>
    </Center>
  );
};

export default Features;
