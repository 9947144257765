import { Box, Heading, Text, Img, Flex } from '@chakra-ui/react';

import data from '@public/meta.json';

export const Offerings: React.FC = () => {
  return (
    <Flex
      px={8}
      py={10}
      flexFlow={['column', 'row wrap']}
      justifyContent={'center'}
      alignItems={'baseline'}
    >
      {(data?.offerings ?? []).map((offering) => (
        <Box
          key={offering.name}
          display={'flex'}
          flexFlow={'column'}
          alignItems={'center'}
          flex={[1, '0 1 26.25rem', '0 1 calc(50%)', '0 1 calc(33%)']}
          my={5}
          p={[0, null, '2rem', '2.5rem']}
          textAlign={'center'}
          data-testid={'container'}
        >
          <Img
            data-test={'icon'}
            src={`/icons/${offering.icon}`}
            alt={offering.name}
            width={'auto'}
            height={'auto'}
            htmlWidth={'115px'}
            htmlHeight={'80px'}
            maxW={{ base: '86px', lg: '115px' }}
            maxH={{ base: '60px', lg: '80px' }}
          />
          <Heading
            as={'h3'}
            py={{ base: 3, lg: 5 }}
            fontSize={['lg', null, 'xl', '2xl']}
          >
            {offering.name}
          </Heading>
          <Text textAlign={'center'}>{offering.description}</Text>
        </Box>
      ))}
    </Flex>
  );
};

export default Offerings;
