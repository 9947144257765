import { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { Box, Flex, IconButton, Link, useDisclosure } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

import {
  ValearnisLogo,
  ValearnisLogoWhite,
  DesktopNav,
  MobileNav,
} from '@components';

export interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'About us',
    href: '/about-us',
  },
  {
    label: 'Subjects',
    href: '/subjects',
  },
  {
    label: 'Pricing',
    href: '/pricing',
  },
  {
    label: 'FAQ',
    href: '/faq',
  },
  {
    label: 'Contact us',
    href: '/contact-us',
  },
  {
    label: 'Log in',
    href: 'https://app.valearnis.com/login',
  },
  {
    label: 'Sign up',
    href: 'https://app.valearnis.com/register',
  },
];

export const Header: React.FC = () => {
  const [headerWhite, setHeaderWhite] = useState(false);
  const [bgOpacity, setBgOpacity] = useState(0);
  const lgLogoWidth = '160px';
  const lgLogoHeight = '41px';
  const smLogoWidth = '140px';
  const smLogoHeight = '36px';
  const { isOpen, onClose, onToggle } = useDisclosure();

  useEffect(function mount() {
    // fade-on-scroll effect for the header's background colour
    function onScroll() {
      if (window.pageYOffset > 50 && headerWhite === false) {
        setHeaderWhite(true);
      } else {
        setHeaderWhite(false);
      }

      if (window.pageYOffset < 150) {
        setBgOpacity(window.pageYOffset / 150);
      } else if (bgOpacity < 1) {
        setBgOpacity(1);
      }
    }

    window.addEventListener('scroll', onScroll);

    return function unMount() {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Box
      as={'header'}
      pos={'fixed'}
      w={'100%'}
      bg={
        isOpen
          ? 'linear-gradient(#1B4DB2, #44229C)'
          : `rgba(255, 255, 255, ${bgOpacity})`
      }
      zIndex={'banner'}
      transition={'background-color .3s ease'}
    >
      <Flex minH={'60px'} py={[2, 4]} px={[4, 6]} align={'center'}>
        <Flex flex={{ base: 1, md: 0 }}></Flex>
        <Flex
          flex={{ base: '1 0 auto' }}
          justify={'space-evenly'}
          flexDir={'row'}
          alignItems={'center'}
        >
          <NextLink href={'/'} passHref>
            <Link
              pos={'relative'}
              width={headerWhite ? smLogoWidth : lgLogoWidth}
              height={headerWhite ? smLogoHeight : lgLogoHeight}
              // transition={'none'}
              transition={'.3s all ease'}
            >
              <Box
                pos={'absolute'}
                opacity={headerWhite && !isOpen ? 1 : 0}
                transition={'.3s all ease'}
              >
                <ValearnisLogo
                  width={headerWhite ? smLogoWidth : lgLogoWidth}
                  height={headerWhite ? smLogoHeight : lgLogoHeight}
                />
              </Box>
              <Box
                pos={'absolute'}
                opacity={headerWhite && !isOpen ? 0 : 1}
                transition={'.3s all ease'}
              >
                <ValearnisLogoWhite
                  width={headerWhite ? smLogoWidth : lgLogoWidth}
                  height={headerWhite ? smLogoHeight : lgLogoHeight}
                />
              </Box>
            </Link>
          </NextLink>
          <Flex display={['none', null, 'flex']} ml={10}>
            <DesktopNav navItems={NAV_ITEMS} headerWhite={headerWhite} />
          </Flex>
        </Flex>
        <Flex
          flex={[1, null, 'auto']}
          ml={-2}
          display={['flex', null, 'none']}
          justify={'end'}
        >
          <IconButton
            minW={10}
            h={10}
            px={0}
            onClick={onToggle}
            icon={
              isOpen ? (
                <CloseIcon w={5} h={5} color={'#fff'} />
              ) : (
                <HamburgerIcon
                  w={6}
                  h={6}
                  color={headerWhite ? '#1B4DB2' : '#fff'}
                  transition={'color .3s ease'}
                />
              )
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
      </Flex>
      <Box
        display={isOpen ? 'flex' : 'none'}
        alignItems={'center'}
        minH={'calc(100vh - 60px)'}
        pb={'60px'}
      >
        <MobileNav onMenuClose={onClose} navItems={NAV_ITEMS} />
      </Box>
    </Box>
  );
};

export default Header;
