/* eslint-disable prettier/prettier */
import { Box, Img, Heading } from '@chakra-ui/react';
import { Swiper as S, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';

// Import Swiper styles
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
// import required modules
import {
  EffectCoverflow,
  Pagination,
  Autoplay,
} from 'swiper/modules';

const Swiper: typeof SwiperCore = S;
const GALLERY_IMAGES = [
  {
    src: 'The-Moon,-Eclipses-and-Seasons-Tilted-earth-axis-diagram-1',
    alt: 'The-Moon,-Eclipses-and-Seasons-Tilted-earth-axis-diagram',
  },
  {
    src: 'English-page-web-01',
    alt: 'English-page-web',
  },
  {
    src: 'HASS-page-web-01',
    alt: 'HASS-page-web',
  },
  {
    src: 'Math-page-web-01',
    alt: 'Math-page-web',
  },
  {
    src: 'Science-Page-web-01',
    alt: 'Science-Page-web',
  },
  {
    src: 'The-Solar-System-and-Planets-The-solar-system-explained-diagram-1',
    alt: 'The-Solar-System-and-Planets-The-solar-system-explained-diagram',
  },
  {
    src: 'The-Moon,-Eclipses-and-Seasons-Lunar-Eclipse-diagram-5',
    alt: 'The-Moon,-Eclipses-and-Seasons-Lunar-Eclipse-diagram',
  },
  {
    src: 'The-Moon,-Eclipses-and-Seasons-Summer-and-Winter-sky-diagram-1',
    alt: 'The-Moon,-Eclipses-and-Seasons-Summer-and-Winter-sky-diagram',
  },
  {
    src: 'HISTORY-YEAR-8-THE-BLACK-DEATH-MEDICAL-TREATMENT-DIAGRAM',
    alt: 'HISTORY-YEAR-8-THE-BLACK-DEATH-MEDICAL-TREATMENT-DIAGRAM',
  },
  {
    src:
      'The-Moon,-Eclipses-and-Seasons-Tilted-earth-axis-diagram-2-seasons-no-orbit-line',
    alt:
      'The-Moon,-Eclipses-and-Seasons-Tilted-earth-axis-diagram-2-seasons-no-orbit-line',
  },
  {
    src: 'Year-9-Earth-Science-Climate-and-Atmosphere-Diagram-2',
    alt: 'Year-9-Earth-Science-Climate-and-Atmosphere-Diagram',
  },
  {
    src: 'Year-9-Science-Climate-and-Atmosphere-Diagram-1',
    alt: 'Year-9-Science-Climate-and-Atmosphere-Diagram',
  },
  {
    src: 'Year-9-Science-plate-boundary-types',
    alt: 'Year-9-Science-plate-boundary-types',
  },
  {
    src: 'HISTORY-YEAR-7-ANCIENT-EGYPT-SOCIETY-GROUPS-DIAGRAM-2-DEFINITIONS',
    alt: 'HISTORY-YEAR-7-ANCIENT-EGYPT-SOCIETY-GROUPS-DIAGRAM-2-DEFINITIONS',
  },
  {
    src:
      'Postive-and-Negative-numbers-Counting-up-positive-and-down-negative-numbers-diagram-1',
    alt:
      'Postive-and-Negative-numbers-Counting-up-positive-and-down-negative-numbers-diagram',
  },
  {
    src: 'The-distributive-Law-example-diagram-3',
    alt: 'The-distributive-Law-example-diagram',
  },
  {
    src: 'Year-7-chemistry-distillation-diagram-1',
    alt: 'Year-7-chemistry-distillation-diagram',
  },
  {
    src: 'YEAR-7-HASS-Greece-Diagram-4',
    alt: 'YEAR-7-HASS-Greece-Diagram',
  },
  {
    src: 'Year-7-HASS-Egyptian-diagram-4',
    alt: 'Year-7-HASS-Egyptian-diagram',
  },
  {
    src: 'Year-8-science-igneous-rocks-layers-of-the-volcano',
    alt: 'Year-8-science-igneous-rocks-layers-of-the-volcano',
  },
];

export const Gallery: React.FC = () => {
  return (
    <Box py={[12, 16, 20, 24, 28]}>
      <Heading
        as={'h2'}
        pb={[12, null, 16, null, 20]}
        textAlign={'center'}
        textTransform={'uppercase'}
      >
        Gallery
      </Heading>
      <Swiper
        effect={'coverflow'}
        initialSlide={3}
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={20}
        grabCursor
        centeredSlides
        pagination={true}
        loop={true}
        modules={[Autoplay, EffectCoverflow, Pagination]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        style={{ overflow: 'visible' }}
        breakpoints={{
          512: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          940: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
        }}
      >
        {GALLERY_IMAGES.map((image) => {
          return (
            <SwiperSlide key={image.src}>
              <Img
                src={`https://cdn.valearnis.com/vw/img/${image.src}.webp`}
                alt={image.alt}
                htmlWidth={'480px'}
                htmlHeight={'480px'}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

export default Gallery;
