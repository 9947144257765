import NextLink from 'next/link';
import { Button, Box, Heading, Link, Text, VStack } from '@chakra-ui/react';

export const HomePageIntro: React.FC = () => (
  <Box
    minHeight={[
      '37.5rem',
      '40rem',
      '47.5rem',
      '50rem',
      '57.5rem',
      '62.5rem',
      '67.5rem',
    ]}
    bgImage={'url("https://cdn.valearnis.com/vw/img/bg-landing-desktop-3.svg")'}
    bgSize={'cover'}
    bgPos={'bottom'}
    bgRepeat={'no-repeat'}
    pt={[28, 44, null, null, 52]}
    pb={20}
    px={14}
    textAlign={'center'}
    color={'white'}
  >
    <Box
      maxW={['23.75rem', null, '28.75rem', '36rem', '40rem', '44rem']}
      m={'0 auto'}
    >
      <Heading
        as={'h1'}
        fontSize={['1.75rem', '1.875rem', '2.5rem', null, '3rem', '3.5rem']}
        fontWeight={'bold'}
        color={'#fff'}
        letterSpacing={'1px'}
      >
        Welcome to Valearnis
      </Heading>
      <Text
        fontSize={['1rem', '1.125rem', '1.25rem', null, '1.5rem', '1.625rem']}
        color={'#fff'}
        letterSpacing={'.5px'}
      >
        A world rich in knowledge, innovation, and adventure! Together, we can
        reinvent education.
      </Text>
      <Text
        fontSize={['0.875rem', '1rem', '1.125rem', null, '1.25rem', '1.375rem']}
        color={'#fff'}
        letterSpacing={'.5px'}
      >
        Use code FREETRIAL for 3 months free!
      </Text>
      <VStack>
        <Box
          width={'25rem'}
          my={8}
          p={{ base: 4, xl: 8 }}
          fontSize={{ xl: '1.25rem' }}
          bgColor={'blue.400'}
          display={{ base: 'block', xl: 'flex' }}
          alignItems={'center'}
          justifyContent={'center'}
          borderRadius={'md'}
          border={'2px solid #fff'}
        >
          <Text mt={3}>
            Valearnis is currently undergoing maintenance, stay tuned for
            Valearnis 2.0!
          </Text>
        </Box>
        <Button
          as={Link}
          width={{ xl: '14.125rem' }}
          mt={4}
          px={{ base: 8, xl: 16 }}
          fontSize={{ xl: '1.25rem' }}
          bgColor={'brand.600'}
          isExternal
          isDisabled={true}
        >
          Log in
        </Button>
        <Button
          as={Link}
          width={{ xl: '14.125rem' }}
          mt={4}
          px={{ base: 8, xl: 16 }}
          fontSize={{ xl: '1.25rem' }}
          bgColor={'brand.600'}
          isExternal
          isDisabled={true}
        >
          Sign up
        </Button>
      </VStack>
    </Box>
  </Box>
);
