import NextLink from 'next/link';
import {
  Box,
  // Flex,
  // Icon,
  Link,
  // Popover,
  // PopoverContent,
  // PopoverTrigger,
  Stack,
  // Text,
} from '@chakra-ui/react';
// import { ChevronRightIcon } from '@chakra-ui/icons';

import { NavItem } from '../..';

interface Props {
  navItems: NavItem[];
  headerWhite: boolean;
}

export const DesktopNav: React.FC<Props> = ({ navItems, headerWhite }) => {
  return (
    <Stack as="nav" direction={'row'} spacing={[4, null, 5, 8]}>
      {navItems.map((navItem) => (
        <Box key={navItem.label}>
          {/* <Popover trigger={'hover'} placement={'bottom-start'} strategy={'fixed'}>
            <PopoverTrigger> */}
          <NextLink href={navItem.href ?? '#'} passHref>
            <Link
              p={2}
              fontSize={['md', null, null, 'lg']}
              fontWeight={600}
              color={headerWhite ? '#1B4DB2' : '#fff'}
              _hover={{
                textDecoration: 'none',
                color: headerWhite ? '#092d77' : '#eee',
              }}
            >
              {navItem.label}
            </Link>
          </NextLink>
          {/* </PopoverTrigger>
            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={'white'}
                p={4}
                rounded={'xl'}
                minW={'sm'}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover> */}
        </Box>
      ))}
    </Stack>
  );
};

// const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
//   return (
//     <NextLink href={href} passHref>
//       <Link
//         role={'group'}
//         display={'block'}
//         p={2}
//         rounded={'md'}
//         _hover={{ bg: 'blue.100' }}
//       >
//         <Stack direction={'row'} align={'center'}>
//           <Box>
//             <Text
//               transition={'all .3s ease'}
//               _groupHover={{ color: 'brand.600' }}
//               fontWeight={500}
//             >
//               {label}
//             </Text>
//             <Text fontSize={'sm'}>{subLabel}</Text>
//           </Box>
//           <Flex
//             transition={'all .3s ease'}
//             transform={'translateX(-10px)'}
//             opacity={0}
//             _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
//             justify={'flex-end'}
//             align={'center'}
//             flex={1}
//           >
//             <Icon color={'brand.600'} w={5} h={5} as={ChevronRightIcon} />
//           </Flex>
//         </Stack>
//       </Link>
//     </NextLink>
//   );
// };

export default DesktopNav;
