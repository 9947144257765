import { Center, Img, Text, Box } from '@chakra-ui/react';

export const Team: React.FC = () => {
  return (
    <Center>
      <Box
        position={'relative'}
        width={'100%'}
        margin={'0 auto'}
        maxW={'1100px'}
        textAlign={'center'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        mx={'3rem'}
      >
        <Img
          data-test={'icon'}
          borderRadius={'1rem'}
          src={'/img/team.png'}
          alt={'Animated Valearnis Team'}
          width={'auto'}
          marginBottom={'2rem'}
          htmlWidth={'1126px'}
          htmlHeight={'475px'}
        />
        <Text margin={'5 auto'}>
          Valearnis, based in Perth, Western Australia, is an EdTech-AI startup
          comprised of a passionate and intuitive team of 15 innovators. Founded
          by Samreid Manez, our team is comprised of AI engineers, full-stack
          developers, data engineers, educators, animators, artists, and
          marketers all working hard to redefine education.
        </Text>
      </Box>
    </Center>
  );
};
